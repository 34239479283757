<template>
  <div class="container">

    <!-- 第一步，选择身份 -->
    <div class="container_signUp" v-if="step == 1">
      <div class="container_signUp_top">
        <h1>Create an account</h1>
        <p>If you are a supplier, you will be able to showcase your products and services and establish connections
          with potential customers. If you are a buyer/customer, you will be able to browse and purchase products.
        </p>
      </div>

      <!-- 选择身份 -->
      <div class="container_signUp_select">
        <div :class="['container_signUp_select_item', selectedIdentityIndex == index ? 'is-active' : '']" v-for="(item, index) in identity" :key="index" @click="selectIdentity(index)">
          <div class="container_signUp_select_item_left">
            <img src="../../assets/images/login/supplier.png" alt="" v-if="item == 'supplier'">
            <img src="../../assets/images/login/buyer.png" alt="" v-if="item == 'buyer'">
            <span>{{ item === 'supplier' ? 'I am a supplier' : 'I am a buyer/customer' }}</span>
          </div>
          <img src="../../assets/images/login/Fill-checkbox.png" alt="" v-if="selectedIdentityIndex == index">
          <img src="../../assets/images/login/Line-checkbox.png" alt="" v-if="selectedIdentityIndex != index">
        </div>

        <div class="container_signUp_select_btn" @click="next">
          Continue {{ step }}/3
        </div>
      </div>

      <!-- 分割线 -->
      <div class="container_signUp_line"></div>
      <div class="container_signUp_toLogin">
        Already have a account <span @click="toLogin">Log in</span>
      </div>
    </div>

    <!-- 第二步，填写用户名和密码 -->
    <div class="container_signUp_loginInformation" v-if="step == 2">
      <div class="container_signUp_loginInformation_top">
        <div class="container_signUp_loginInformation_top_title">
          <img src="../../assets/images/login/alt-left.png" alt="" @click="back">
          <h1>{{ identity2[selectedIdentityIndex] }}</h1>
        </div>

        <p>Please fill in the login information.</p>
      </div>

      <div class="container_signUp_loginInformation_input">

        <el-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="rules" label-position="top" class="el_form_item">
          <el-form-item prop="username">
            <el-input class="container_signUp_loginInformation_input_username" v-model="ruleForm.username" type="text" autocomplete="off" placeholder="Enter your user name" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input class="container_signUp_loginInformation_input_pass" v-model="ruleForm.password" type="password" autocomplete="off" placeholder="Enter your password" />
          </el-form-item>
          <el-form-item prop="passwordAgain">
            <el-input class="container_signUp_loginInformation_input_pass" v-model="ruleForm.passwordAgain" type="password" autocomplete="off" placeholder="Confirm password" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm(ruleFormRef)">Continue {{ step }}/3</el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>

    <!-- 第三步，填写公司信息 I am a supplier-->
    <div class="container_signUp_companyInformation" v-if="step == 3 && selectedIdentityIndex == 0">
      <div class="container_signUp_companyInformation_top">
        <div class="container_signUp_companyInformation_top_title">
          <!--                    <img src="../../assets/images/login/alt-left.png" alt="" @click="back">-->
          <h1>{{ identity2[selectedIdentityIndex] }}</h1>
        </div>

        <p>Please fill in the basic information.</p>
      </div>

      <div class="container_signUp_companyInformation_input">
        <el-form ref="form" :model="form3" :rules="rulesForm3">
          <!-- Corporate Name -->
          <el-form-item label="Corporate Name (Chinese)" prop="corporateNameChinese">
            <el-input v-model="form3.corporateNameChinese" placeholder="Enter Corporate Name(China)" @blur="transMethod"></el-input>
          </el-form-item>
          <el-form-item label="Corporate Name (English)" prop="corporateName">
            <el-input v-model="form3.corporateName" placeholder="Enter Corporate Name(English)"></el-input>
          </el-form-item>

          <!-- Country/Territory 选择-->
          <el-form-item label="Country/Territory" prop="country">
            <el-select v-model="form3.country" placeholder="Select Country/Territory">
              <el-option v-for="(item, index) in country" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <!-- province/state,city -->
          <el-form-item label="Province/State/City" prop="province">
            <el-cascader placeholder="Select Province/State/City" :options="regionData" v-model="form3.province" @change="onChange">
            </el-cascader>
          </el-form-item>

          <!-- postal code -->
          <el-form-item label="Postal Code" prop="postalCode">
            <el-input v-model="form3.postalCode" placeholder="Enter Postal Code"></el-input>
          </el-form-item>

          <!-- Company Address -->
          <el-form-item label="Company Address" prop="companyAddress">
            <el-input v-model="form3.companyAddress" placeholder="Enter Company Address"></el-input>
          </el-form-item>

          <el-form-item label="Landline" prop="companySwitchboard">
            <el-input v-model="form3.companySwitchboard" placeholder="Enter Landline"></el-input>
          </el-form-item>
          <el-form-item label="Contact Name" prop="contacts">
            <el-input v-model="form3.contacts" placeholder="Enter Contact Name"></el-input>
          </el-form-item>
          <el-form-item label="Contact Email" prop="contactEmail">
            <el-input v-model="form3.contactEmail" placeholder="Enter Contact Email"></el-input>
          </el-form-item>
          <el-form-item label="Contact Phone" prop="contactPhone">
            <el-input v-model="form3.contactPhone" maxlength="20" placeholder="Enter Contact Phone"></el-input>
          </el-form-item>
          <el-form-item label="Contact WeChat" prop="contactWeChat">
            <el-input v-model="form3.contactWeChat" placeholder="Enter Contact WeChat"></el-input>
          </el-form-item>

          <el-form-item label="Last Response Time" prop="lastResponseTime">
            <el-date-picker v-model="form3.lastResponseTime" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="Last Response Time"></el-date-picker>
          </el-form-item>
          <el-form-item label="Transactions" prop="transactions">
            <el-input v-model="form3.transactions" placeholder="Enter Transactions"></el-input>
          </el-form-item>
          <el-form-item label="On Time Delivery Rate(%)" prop="onTimeDeliveryRate">
            <el-input v-model="form3.onTimeDeliveryRate" placeholder="Enter On Time Delivery Rate" type="number" onkeyup="this.value= this.value.match(/^\+?[1-9]\d*$/) ? this.value.match(/^\+?[1-9]\d*$/)[0] : ''"></el-input>
          </el-form-item>

          <!-- 工厂地址 -->
          <div class="el-line">
            <span>Shipping Warehouse Address</span>
            <div :class="['el-line_radio', radio ? 'el-line_radioChange' : '']" @click="radioChange">
              <div class="el-line_radio_left">
                <img src="../../assets/images/customer/select.png" alt="" v-show="radio">
              </div>
              <div class="el-line_radio_text">Same As Company Address</div>
            </div>
          </div>

          <!-- 工厂 Country/Territory 选择-->
          <el-form-item label="Country/Territory" prop="country2">
            <el-select v-model="form3.country2" placeholder="Select Country/Territory">
              <el-option v-for="(item, index) in country" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <!-- province/state,city -->
          <el-form-item label="Province/State/City" prop="province2">
            <el-cascader placeholder="Select Province/State/City" :options="regionData" v-model="form3.province2">
            </el-cascader>
          </el-form-item>

          <!-- postal code -->
          <el-form-item label="Postal Code" prop="postalCode">
            <el-input v-model="form3.postalCode2" placeholder="Enter Postal Code"></el-input>
          </el-form-item>

          <el-form-item label="Address" prop="shippingWarehouseAddress">
            <el-input v-model="form3.shippingWarehouseAddress" placeholder="Enter Shipping Warehouse Address"></el-input>
          </el-form-item>

          <div class="el-line-bottom"></div>

          <!-- Shipping Port Preference的多选输入框 -->
          <el-form-item label="Shipping Port Preference" prop="shippingPortPreference">
            <el-input v-model="form3.shippingPortPreference" placeholder="Enter Shipping Port Preference"></el-input>
          </el-form-item>

          <!-- Currency Preference -->
          <el-form-item label="Currency Preference" prop="currencyPreference">
            <el-select v-model="form3.currencyPreference" placeholder="Select Currency Preference">
              <el-option v-for="(item, index) in currencyPreference" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <!-- Established in -->
          <el-form-item label="Established In" prop="establishedIn">
            <el-date-picker v-model="form3.establishedIn" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" placeholder="Select Established In"></el-date-picker>
          </el-form-item>

          <!-- Company size (number of people) -->
          <el-form-item label="Company Size (No. of Employee)" prop="companySize">
            <el-input v-model="form3.companySize" placeholder="Enter Company Size (No. of Employee)"></el-input>
          </el-form-item>

          <!-- 选择Industry -->
          <el-form-item label="Industry" prop="industry">
            <el-input v-model="form3.industry" placeholder="Enter Industry"></el-input>
          </el-form-item>

          <!-- 多选 Mainly covering the market -->
          <el-form-item class="container_signUp_companyInformation_input_select" label="Mainly Covering The Market" prop="mainlyCoveringTheMarket">
            <el-input v-model="form3.mainlyCoveringTheMarket" placeholder="Enter Mainly Covering The Market"></el-input>
          </el-form-item>

          <!-- 上传资质证书，拖拽上传文件，不自动上传 -->
          <el-form-item label="Upload Qualification Certificate" prop="uploadQualificationCertificate">
            <el-upload drag :action="uploadFileUrl" :headers="headers" :on-error="handleUploadError" :before-upload="beforeUpload" :on-remove="handleRemove" :on-success="handleAvatarSuccess" multiple :file-list="fileList">
              <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
            </el-upload>
          </el-form-item>

          <!-- Company products -->
          <el-form-item label="Company Products" prop="companyProducts">
            <el-input type="textarea" v-model="form3.companyProducts" placeholder="Enter Company Products"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm3()">Continue {{ step }}/3</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 第三步-2，填写公司信息 I am a buyer/customer -->
    <div class="container_signUp_companyInformation container_signUp_customerInformation " v-if="step == 3 && selectedIdentityIndex == 1">
      <div class="container_signUp_companyInformation_top">
        <div class="container_signUp_companyInformation_top_title">
          <!--                    <img src="../../assets/images/login/alt-left.png" alt="" @click="back">-->
          <h1>{{ identity2[selectedIdentityIndex] }}</h1>
        </div>

        <p>Please fill in the basic information.</p>
      </div>

      <div class="container_signUp_companyInformation_input">
        <el-form ref="form" :model="form4" :rules="rulesForm4">
          <!-- Corporate Name -->
          <el-form-item label="Corporate Name" prop="corporateName">
            <el-input v-model="form4.corporateName" placeholder="Enter Corporate Name"></el-input>
          </el-form-item>

          <el-form-item label="Position" prop="duties">
            <el-input v-model="form4.duties" placeholder="Enter Position"></el-input>
          </el-form-item>

          <!-- Company Address -->
          <el-form-item label="Company Address" prop="companyAddress">
            <el-input v-model="form4.companyAddress" placeholder="Enter Company Address"></el-input>
          </el-form-item>

          <el-form-item label="Landline" prop="companySwitchboard">
            <el-input v-model="form4.companySwitchboard" placeholder="Enter Landline"></el-input>
          </el-form-item>
          <el-form-item label="Contact Name" prop="contacts">
            <el-input v-model="form4.contacts" placeholder="Enter Contact Name"></el-input>
          </el-form-item>
          <el-form-item label="Contact Email" prop="contactEmail">
            <el-input v-model="form4.contactEmail" placeholder="Enter Contact Email"></el-input>
          </el-form-item>
          <el-form-item label="Contact Phone" prop="contactPhone">
            <el-input v-model="form4.contactPhone" maxlength="20" placeholder="Enter Contact Phone"></el-input>
          </el-form-item>
          <!-- Default receiving address -->
          <el-form-item label="Primary Delivery Address" prop="defaultReceivingAddress">
            <el-input v-model="form4.defaultReceivingAddress" placeholder="Enter Primary Delivery Address"></el-input>
          </el-form-item>
          <!-- CRO No. -->
          <el-form-item label="CRO No." prop="croNo">
            <el-input v-model="form4.croNo" placeholder="Enter CRO No."></el-input>
          </el-form-item>
          <!-- EORI No. (VAT No.) -->
          <el-form-item label="EORI No (VAT NO)" prop="eoriNo">
            <el-input v-model="form4.eoriNo" placeholder="Enter EORI No (VAT NO)"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm4">Continue {{ step }}/3</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 步骤指示 -->
    <div class="container_step">
      <div :class="['container_step_item', i == step ? 'container_step_itemActive' : '']" v-for="i in 3" :key="i">
      </div>
    </div>
  </div>
</template>

<script setup name="ComponentSignUp">
import { reactive, ref, onMounted } from 'vue'
import { toRefs } from '@vue/reactivity'
import { ElMessage, ElMessageBox } from 'element-plus'
import { setUserType } from '@/utils/auth'
import { regionData } from 'element-china-area-data'
// 跳转登录
import { defineProps, defineEmits } from 'vue'
import { register } from '@/api/user'
import { trans } from '@/api/common'
import { getToken, getUserId, setToken, setUserId } from '@/utils/auth'
import { target } from '@/utils/URL'
import { useRouter } from 'vue-router'
import { getMuDidiPortList } from '@/api/transport'


const router = useRouter()
// 选择身份
const identity = reactive(['supplier', 'buyer'])
const identity2 = reactive(['I am a supplier', 'I am a buyer/customer'])
const selectedIdentityIndex = ref(0)
const selectIdentity = (index) => {
  selectedIdentityIndex.value = index
}
const userType = ref('0')
const provinceName = ref('')
// step2

const ruleFormRef = ref()
const rulesForm3 = ref({
  corporateName: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  corporateNameChinese: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  country: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  province: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companyAddress: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  postalCode: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companySwitchboard: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contacts: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactEmail: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactPhone: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactWeChat: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  country2: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  province2: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  postalCode2: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  shippingWarehouseAddress: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  shippingPortPreference: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  currencyPreference: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  establishedIn: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companySize: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  industry: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  mainlyCoveringTheMarket: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companyProducts: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  lastResponseTime: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  transactions: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  onTimeDeliveryRate: [
    { required: true, message: 'Required', trigger: 'blur' }
  ]
})

const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Required input the password'))
  } else {
    callback()
  }
}
const validatePassAgain = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Required input the password again'))
  } else {
    callback()
  }
}
const validateUsername = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Required input the username'))
  } else {
    callback()
  }
}

const ruleForm = reactive({
  username: '',
  password: '',
  passwordAgain: '',
  type: '',
  step: ''
})
const rules = reactive({
  password: [{ validator: validatePass, trigger: 'blur' }],
  passwordAgain: [{ validator: validatePassAgain, trigger: 'blur' }],
  username: [{ validator: validateUsername, trigger: 'blur' }]
})

const submitForm = (formEl) => {
  // 暂不验证
  // next()

  if (!formEl) return
  formEl.validate(async (valid) => {
    if (valid) {
      // 去创建账号密码
      const { step, type } = toRefs(ruleForm)

      step.value = '1'
      if (selectedIdentityIndex.value === 0) {
        type.value = '1'
        userType.value = '1'
      } else {
        type.value = '2'
        userType.value = '2'
      }
      const result = await register(ruleForm)
      if (result.code === 200) {
        setUserId(result.data.userId)
        next()
      }
      console.log('submit!')
    } else {
      return false
    }
  })
}

// step2 -end

// step3 供应商注册
const form3 = reactive({
  corporateName: '',
  corporateNameChinese: '',
  country: '中国',
  province: '',
  companyAddress: '',
  postalCode: '',
  companySwitchboard: '',
  contacts: '',
  contactEmail: '',
  contactPhone: '',
  contactWeChat: '',
  country2: '',
  province2: '',
  postalCode2: '',
  shippingWarehouseAddress: '',
  shippingPortPreference: [],
  currencyPreference: '',
  establishedIn: '',
  companySize: '',
  industry: '',
  mainlyCoveringTheMarket: '',
  companyProducts: '',
  userId: null,
  step: '',
  type: '',
  certificates: [],
  provinceName: '',
  lastResponseTime: '',
  transactions: '',
  onTimeDeliveryRate: ''
})

const currencyPreference = ref(['USD', 'EUR', 'CNY'])
const country = ref(['中国'])

// 工厂地址选择
const radio = ref(false)
const radioChange = () => {
  radio.value = !radio.value
  if (radio.value) {
    form3.country2 = form3.country
    form3.province2 = form3.province
    form3.postalCode2 = form3.postalCode
    form3.shippingWarehouseAddress = form3.companyAddress
  } else {
    form3.country2 = ''
    form3.province2 = ''
    form3.postalCode2 = ''
    form3.shippingWarehouseAddress = ''
  }
}

// 上传文件

const uploadFileUrl = ref(target + 'common/uploadNotLogin') // 上传的图片服务器地址
const headers = ref({
  Authorization: getToken()
})

const beforeUpload = (file) => {
  /*const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    ElMessage.error('The size of the uploaded avatar image cannot exceed 2MB!')
  }
  return isLt2M*/
  return true
}

const fileList = ref([])
const picUrls = ref([])
const handleRemove = (uploadFile, uploadFiles) => {
  picUrls.value = []
  for (const e of uploadFiles) {
    picUrls.value.push(e.response.url)
  }
}
const handleAvatarSuccess = (response, uploadFile, uploadFiles) => {
  picUrls.value = []
  for (const e of uploadFiles) {
    picUrls.value.push(e.response.url)
  }
  console.log(picUrls.value)
}
// 上面是表单数据

const submitForm3 = async () => {
  console.log('Form data:', form3.value)
  // if (!/^1[3456789]\d{9}$/.test(form3.contactPhone)) {
  //   ElMessage.error('The format of the mobile phone number is incorrect')
  //   return
  // }
  const regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  if (!regEmail.test(form3.contactEmail)) {
    ElMessage.error('The mail format is incorrect')
    return
  }
  const { step, type, userId, certificates } = toRefs(form3)
  step.value = '2'
  if (provinceName.value) {
    form3.provinceName = provinceName.value
  } else {
    ElMessage.error('Please Select Province/State/City')
    return
  }
  if (!form3.province2) {
    ElMessage.error('Please Shipping Warehouse Address Select Province/State/City')
    return
  }
  if (!form3.establishedIn || form3.establishedIn == '') {
    ElMessage.error('Please Select Established')
    return
  }
  type.value = userType.value
  console.log(picUrls.value)
  certificates.value = picUrls.value
  userId.value = getUserId()
  const result = await register(form3)
  if (result.code === 200) {
    setToken(result.data.token)
    setUserType(1)
    router.push({
      path: 'supplierHome'
    })
  }
  // 表单提交
}

// step3 -end

const rulesForm4 = ref({
  corporateName: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companyAddress: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companySwitchboard: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contacts: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactEmail: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactPhone: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  defaultReceivingAddress: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  croNo: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  eoriNo: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  duties: [
    { required: true, message: 'Required', trigger: 'blur' }
  ]
})

// step4 客户注册
const form4 = reactive({
  duties: '',
  corporateName: '',
  companyAddress: '',
  companySwitchboard: '',
  contacts: '',
  contactEmail: '',
  contactPhone: '',
  defaultReceivingAddress: '',
  croNo: '',
  eoriNo: '',
  step: '',
  type: '',
  userId: null
})

// 上面是表单数据

const submitForm4 = async () => {
  console.log('Form data:', form4.value)
  /* if (!/^1[3456789]\d{9}$/.test(form4.contactPhone)) {
     ElMessage.error('The format of the mobile phone number is incorrect')
     return
   }*/
  const regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  if (!regEmail.test(form4.contactEmail)) {
    ElMessage.error('The mailbox format is incorrect')
    return
  }
  const { step, type, userId } = toRefs(form4)
  step.value = '2'
  type.value = userType.value
  userId.value = getUserId()
  const result = await register(form4)
  if (result.code === 200) {
    setToken(result.data.token)
    setUserType(2)
    router.push({
      path: 'home'
    })
  }

  // 表单提交
}

// 步骤指示
const step = ref(1)

// 下一步
const next = () => {
  step.value++
  if (step.value > 3) {
    step.value = 3
  }
}

// 上一步
const back = () => {
  step.value--
  if (step.value < 1) {
    step.value = 1
  }
}

const emit = defineEmits(['toSignUpPage'])
const props = defineProps({
  step: String,
  type: String
})
const checkSignUpStep = () => {
  if (props.step === '3') {
    step.value = '3'
    const { type } = toRefs(ruleForm)
    type.value = props.type
    userType.value = props.type
    if (type.value === '1') {
      selectedIdentityIndex.value = 0
    } else {
      selectedIdentityIndex.value = 1
    }
  }
}
checkSignUpStep()
const toLogin = () => {
  emit('changeShow', true)
}
const onChange = (value) => {
  if (regionData.length > 0) {
    for (const item of regionData) {
      if (item.value === value[0].toString()) {
        provinceName.value = item.label
        break;
      }
    }
  }
}

const transMethod = async () => {
  if (form3.corporateNameChinese) {
    console.log(form3.corporateNameChinese)
    var name = form3.corporateNameChinese
    const res = await trans(name)
    if (res.code === 200) {
      form3.corporateName = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  &_signUp {
    width: 1200px;
    margin: 0 auto;
    margin-top: 150px;

    line-height: 1;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 2px 0px rgba(36, 89, 2, 0.3);
    overflow: hidden;

    &_top {
      width: 400px;
      margin: 0 auto;
      margin-top: 50px;

      h1 {
        font-size: 28px;
        line-height: 1;
        font-weight: 700;
        color: rgba(24, 24, 24, 1);
        margin: 50px auto 20px;
      }

      P {
        font-size: 12px;
        line-height: 2;
        color: rgba(173, 173, 173, 0.8);
        text-align: justify;
        margin: 0;
        margin-bottom: 40px;
      }
    }

    // step1选择身份
    &_select {
      width: 400px;
      margin: 0 auto;

      &_item {
        width: 100%;
        height: 80px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 1);
        border: 2px solid rgba(173, 173, 173, 0.2);
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;

        &_left {
          display: flex;
          align-items: center;
          margin-left: 20px;

          img {
            width: 24px;
            height: 24px;
            margin-right: 20px;
          }

          span {
            font-size: 20px;
            line-height: 1;
            font-weight: 700;
            color: rgba(24, 24, 24, 1);
          }
        }

        img {
          width: 24px;
          height: 24px;
          margin-right: 20px;
        }
      }

      .is-active {
        border: 2px solid rgba(39, 174, 96, 1);
      }

      &_btn {
        width: 400px;
        border-radius: 6px;
        background-color: rgba(39, 174, 96, 1);
        font-size: 16px;
        line-height: 1;
        padding: 18px 0;
        color: #fff;
        text-align: center;
        font-weight: 700;
        margin: 40px 0 50px;
      }
    }

    &_line {
      width: 400px;
      height: 1px;
      background-color: rgba(173, 173, 173, 0.3);
      margin: 20px auto 40px;
    }

    &_toLogin {
      width: 400px;
      margin: 0 auto 40px;
      font-size: 14px;
      line-height: 1;
      color: rgba(24, 24, 24, 0.8);
      text-align: center;

      span {
        color: rgba(39, 174, 96, 1);
        cursor: pointer;
        margin-left: 10px;
      }
    }

    // step2填写登录信息
    &_loginInformation {
      width: 1200px;
      margin: 0 auto;
      margin-top: 150px;
      line-height: 1;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.4);
      box-shadow: 0px 0px 2px 0px rgba(36, 89, 2, 0.3);
      overflow: hidden;

      // 标题
      &_top {
        width: 400px;
        margin: 50px auto 40px;

        &_title {
          display: flex;
          font-size: 0;

          img {
            height: 28px;
            margin-right: 30px;
            cursor: pointer;
          }

          h1 {
            font-size: 28px;
            line-height: 1;
            font-weight: 800;
            color: rgba(24, 24, 24, 1);
            margin: 0;
          }
        }

        p {
          font-size: 12px;
          line-height: 1;
          color: rgba(173, 173, 173, 0.8);
        }
      }

      // 输入框
      &_input {
        width: 400px;
        margin: 0 auto;

        ::v-deep .el-form {
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .el-form-item {
            margin-bottom: 20px;
            width: 400px;
            display: flex;
            flex-direction: column;

            .el-form-item__content {
              .el-input {
                height: 46px;
                height: 60px;

                .el-input__wrapper {
                  padding: 0px 20px;
                  box-shadow: none;
                  background-color: rgba(255, 255, 255, 1);
                  height: 60px;
                  border: 2px solid rgba(173, 173, 173, 0.2);
                  box-sizing: border-box;

                  .el-input__inner {
                    color: rgba(24, 24, 24, 1);
                    font-size: 16px;

                    &::placeholder {
                      color: rgba(173, 173, 173, 0.6);
                    }
                  }
                }

                .is-focus {
                  box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                  border: 2px solid rgba(39, 174, 96, 1);
                  box-sizing: border-box;
                }
              }
            }

            .el-button {
              width: 400px;
              height: 60px;
              border-radius: 6px;
              background-color: rgba(39, 174, 96, 1);
              box-shadow: none;
              border: none;
              font-size: 16px;
              line-height: 1;
              font-weight: 700;
              margin-top: 20px;
              margin-bottom: 40px;
            }
          }
        }

        &_username,
        &_pass {
          position: relative;

          ::v-deep .el-input__inner {
            padding-left: 30px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 44px;
            height: 100%;
            background: url('../../assets/images/login/login_user1.png') no-repeat;
            background-size: 24px 24px;
            background-position: center right;
            z-index: 99;
          }
        }

        &_pass {

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 44px;
            height: 100%;
            background: url('../../assets/images/login/login_pass.png') no-repeat;
            background-size: 24px 24px;
            background-position: center right;
            z-index: 99;
          }
        }
      }
    }

    // step3填写登录信息 供应商
    &_companyInformation {
      width: 1200px;
      margin: 0px auto;
      margin-top: 150px;
      line-height: 1;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.4);
      box-shadow: 0px 0px 2px 0px rgba(36, 89, 2, 0.3);
      overflow: hidden;

      // 标题
      &_top {
        width: 830px;
        margin: 50px auto 40px;

        &_title {
          display: flex;
          font-size: 0;

          img {
            height: 28px;
            margin-right: 30px;
            cursor: pointer;
          }

          h1 {
            font-size: 28px;
            line-height: 1;
            font-weight: 800;
            color: rgba(24, 24, 24, 1);
            margin: 0;
          }
        }

        p {
          font-size: 12px;
          line-height: 1;
          color: rgba(173, 173, 173, 0.8);
        }
      }

      // 供应商提交基本信息
      &_input {
        width: 830px;
        margin: 0 auto;
        padding-bottom: 0px;

        .el-line {
          width: 100%;
          border-top: 1px solid rgba(173, 173, 173, 0.3);
          padding-top: 10px;
          margin: 20px auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 13px;

          &_radio {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &_left {
              width: 18px;
              height: 18px;
              border-radius: 4px;
              border: 1px solid rgba(173, 173, 173, 1);
              margin-right: 5px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 16px;
                height: 16px;
              }
            }
          }

          &_radioChange {
            color: rgba(39, 174, 96, 1);

            .el-line_radio_left {
              border-color: rgba(39, 174, 96, 1);
            }
          }
        }

        .el-line-bottom {
          width: 100%;
          border-top: 1px solid rgba(173, 173, 173, 0.3);
          // padding-top: 10px;
          margin: 0px auto 30px;
        }

        ::v-deep .el-form {
          width: 830px;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .el-form-item {
            margin-bottom: 30px;
            width: 400px;
            display: flex;
            flex-direction: column;

            .el-form-item__label {
              width: 400px;
              justify-content: flex-start;
              font-size: 14px;
              line-height: 1;
              height: 14px;
              margin-bottom: 10px;
              color: rgba(24, 24, 24, 1);
              font-weight: 300;
            }

            .el-form-item__content {
              align-items: flex-start;

              .el-input {

                .el-input__wrapper {
                  padding: 10px 20px;
                  box-shadow: none;
                  background-color: rgba(255, 255, 255, 1);
                  border: 1px solid rgba(173, 173, 173, 0.3);

                  .el-input__inner {
                    color: rgba(24, 24, 24, 1);
                    font-size: 16px;
                    line-height: 1;

                    &::placeholder {
                      color: rgba(173, 173, 173, 0.6);
                    }
                  }
                }

                .is-focus {
                  box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                  border: 1px solid rgba(39, 174, 96, 1);
                  box-sizing: border-box;
                }
              }

              .el-select {
                width: 400px;

                .el-input__wrapper.is-focus {
                  box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1) !important;
                  border: 1px solid rgba(39, 174, 96, 1);
                  box-sizing: border-box;
                }

                .is-focus {
                  box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .1);
                  // border: 1px solid rgba(39, 174, 96, 1);
                  box-sizing: border-box;
                }

              }

              .el-date-editor.el-input {
                width: 400px;

                .el-input__wrapper {
                  padding: 25px 20px;
                }
              }

              .el-upload {
                width: 400px;
              }

              .el-textarea__inner {
                padding: 10px 20px;
                box-shadow: none;
                background-color: rgba(255, 255, 255, 1);
                border: 1px solid rgba(173, 173, 173, 0.3);

                .el-input__inner {
                  color: rgba(24, 24, 24, 1);
                  font-size: 16px;
                  line-height: 1;

                  &::placeholder {
                    color: rgba(173, 173, 173, 0.6);
                  }
                }
              }

            }

            .el-button {
              border-radius: 4px;
              background-color: rgba(39, 174, 96, 1);
              box-shadow: none;
              border: none;
              font-size: 14px;
              line-height: 14px;
              font-weight: 700;
              padding: 27px 30px;
            }

            .el-cascader {
              width: 400px;
            }

            &:last-child {
              width: 1200px;
              justify-content: center;
              align-items: center;
              margin: 60px auto;
            }
          }
        }
      }
    }

  }

  &_step {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 300px;

    &_item {
      width: 100px;
      height: 5px;
      border-radius: 10px;
      background-color: rgba(36, 89, 2, 0.1);
      margin: 0 10px;
    }

    &_itemActive {
      background-color: rgba(39, 174, 96, 1);
    }
  }
}
</style>
